import React from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import "./App.css";

const loading = () => (
  <div className="animated fadeIn pt-3 text-center">Loading...</div>
);

const DefaultLayoutCompany = React.lazy(() =>
  import("../src/container/defaultLayout/defaultLayout")
);
const Login = React.lazy(() => import("./views/Pages/Login"))
const Page404 = React.lazy(() => import("./views/Pages/Page404"))
const Register = React.lazy(() => import("./views/Pages/Register"))
const ForgetPassword = React.lazy(() => import("./views/Pages/forgetPassword"))
const ResetPassword = React.lazy(() => import("./views/Pages/ResetPassword"))
class App extends React.Component {
  constructor(props) {
    super()
    this.state = {
    }
  }
  render() {
    const { role } = this.state;
    return (
      <div className="page-container">
        <Router>
          <React.Suspense fallback={loading()}>
            <Switch>
              <Route exact path="/login" name="Login Page" render={props => <Login {...props} />} />
              <Route exact={false} path="/register/:id" name="Register Page" render={props => <Register {...props} />} />
              <Route exact path="/forget_password" name="Forget password" render={props => <ForgetPassword {...props} />} />
              <Route exact={false} path="/auth/reset_password/:token" name="Reset password" render={props => <ResetPassword {...props} />} />
              <Route path="/" name="Home" render={props => <DefaultLayoutCompany {...props} />} />
            </Switch>
          </React.Suspense>
        </Router>


      </div>
    );
  }
}

export default App;

